import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { usePrevious } from '../hooks'
import { useQuery } from 'react-query'
import { fetchActions } from '../queries'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { toTitleCase } from '../pages/App'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { Pagination, usePagination } from '@toasttab/buffet-pui-pagination'
import ActionStatus from './ActionStatus'

const LinkToActionDetails = ({ action }) => (
  <>
    <strong>{action.revision}</strong> #{action.deploymentNumber}:&nbsp;
    {action.serviceName}
    <Link to={`/${action.repositoryName}/actions/${action.action_id}`}>
      &nbsp;&#40;Action&#41;
    </Link>
  </>
)

const LinkToBuildDetails = ({ action }) => (
  <Link to={`/${action.repositoryName}/builds/${action.buildId}`}>Build</Link>
)

const LinkToDestroy = ({ action }) => (
  <div className='grid justify-items-center'>
    <IconButton
      className='inline-flex'
      as='a'
      href={`https://tdp.eng.toasttab.com/destroy?repoName=${
        action.repositoryName
      }&revision=${action.revision}&serviceName=${action.repositoryName.replace(
        'toast-',
        ''
      )}&deploymentNumber=${action.deploymentNumber}`}
      icon={<DeleteIcon aria-label='Destroy service' />}
      size='sm'
    />
  </div>
)

const convertUTCToDate = (utc) => {
  if (!utc) {
    return ''
  }
  let date = new Date(utc)
  return date.toLocaleString()
}

function MerryGoRound() {
  return null
}

const ActionsList = ({
  repoName,
  refetchActions,
  setRefetchActions,
  currentPage,
  onSetPage
}) => {
  const [previousKeys, setPreviousKeys] = useState([])

  const [currentKey, setCurrentKey] = useState()

  const getAutomatedActionsText = (action) => {
    return action.actionSource === null
      ? 'AUTOMATED'
      : `AUTOMATED (${action.actionSource})`
  }

  useEffect(() => {
    setPreviousKeys([])
    setCurrentKey(null)
    setRefetchActions(false)
  }, [repoName, refetchActions, setRefetchActions])
  const { data, error, isLoading, refetch } = useQuery(
    [
      'fetchActions',
      {
        repoName,
        currentPage
      }
    ],
    fetchActions
  )
  const paginationProps = usePagination({
    totalPageCount: data !== undefined ? data.totalPages : 0,
    currentPage,
    onChangePage: (pageToGoTo) => onSetPage(pageToGoTo)
  })

  const pagination = () => {
    if (data?.data !== undefined) {
      return (
        <div className='flex flex-col items-center my-4'>
          <Pagination {...paginationProps} />
        </div>
      )
    }
  }

  if (isLoading) {
    return (
      <div className='relative h-60'>
        <div className='pin-center'>
          <MerryGoRound />
        </div>
      </div>
    )
  }

  if (error) {
    return <p>An error happened: ${JSON.stringify(error)}</p>
  }

  if (refetchActions) {
    refetch().catch((error) => console.log(error))
  }

  const nextKey = data.lastKey ? JSON.stringify(data.lastKey) : null
  const toRow = (action) => (
    <Row key={action.action_id}>
      <Cell className='pt-1 pb-1'>
        {action.actionType === 'DEPLOY' && action.status === 'SUCCEEDED' ? (
          <LinkToDestroy action={action} />
        ) : (
          ''
        )}
      </Cell>
      <Cell>
        <LinkToActionDetails action={action} />
      </Cell>
      <Cell>{toTitleCase(action.actionType)}</Cell>
      <Cell>
        {action.deploymentTrigger === 'AUTOMATED'
          ? getAutomatedActionsText(action)
          : action.userEmail}
        {action.buildId !== null ? (
          <Link to={`/${action.repositoryName}/builds/${action.buildId}`}>
            &nbsp;&#40;Build&#41;
          </Link>
        ) : (
          <></>
        )}
      </Cell>
      <Cell>{convertUTCToDate(action.startedAt)}</Cell>
      <Cell>{toTitleCase(action.toastEnvironment)}</Cell>
      <Cell>
        <ActionStatus status={action.status} />
      </Cell>
    </Row>
  )

  // Intentionally limiting the columns we're adding til we agree on what we want to display
  return (
    <>
      <CardContainer noPadding className='overflow-auto' verticalEdge='rounded'>
        <Table>
          <Head>
            <Row>
              <HeadingCell id='shortcuts'></HeadingCell>
              <HeadingCell>Action ID</HeadingCell>
              <HeadingCell>Action Type</HeadingCell>
              <HeadingCell>Triggered By</HeadingCell>
              <HeadingCell>Started At</HeadingCell>
              <HeadingCell>Environment</HeadingCell>
              <HeadingCell>Status</HeadingCell>
            </Row>
          </Head>
          <Body>
            {data.data !== undefined ? data.data.map(toRow) : data.map(toRow)}
          </Body>
        </Table>

        {pagination()}
      </CardContainer>
    </>
  )
}

export default ActionsList
